import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, isRef as _isRef, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"color":"#fff"} }
const _hoisted_7 = { class: "w-full flex" }
const _hoisted_8 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { class: "mx-4" }
const _hoisted_11 = { class: "flex items-center flex-wrap" }
const _hoisted_12 = { class: "mt-4 flex justify-center" }
const _hoisted_13 = { class: "flex ml-2" }
const _hoisted_14 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_15 = { class: "btn w-full mt-4" }
const _hoisted_16 = { style: {"width":"150px"} }
const _hoisted_17 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_18 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "w-full flex justify-center mt-4" }
const _hoisted_22 = { style: {"color":"#fff"} }
const _hoisted_23 = { class: "w-full flex" }
const _hoisted_24 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_25 = { class: "flex items-center" }
const _hoisted_26 = { class: "mx-4" }
const _hoisted_27 = { class: "flex items-center flex-wrap" }
const _hoisted_28 = { class: "mt-4 flex justify-center" }
const _hoisted_29 = { class: "flex ml-2" }
const _hoisted_30 = {
  key: 0,
  class: "ml-2"
}

import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { SelectProps } from "ant-design-vue";
import { icons } from "../../common/icons";
import Icon from "@ant-design/icons-vue";
import { v4 as uuidv4 } from "uuid";
import { message, FormInstance } from "ant-design-vue";
import { getLanguage } from "../../common/translate";
import {
  transformDate,
  getGroupListByFactory,
  transformDate2,
  getRealFactoryIdList,
  getGroupName,
  getFactoryName
} from "../../common/tools";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { Dayjs } from "dayjs";
import * as echarts from "echarts";


export default /*@__PURE__*/_defineComponent({
  __name: 'feedback',
  setup(__props) {

let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
}
getLang();

let searchModel: any = ref({
  skip: 1,
  take: 20,
  groupId: null,
  machineId: null
})
let groupList: any = ref([]);
let machineList: any = ref([]);
let statusList = [
  { label: '已反馈', value: 1 },
  { label: '已调整', value: 2 },
  { label: '已确认', value: 3 },
  { label: '已关闭', value: 4 }
]
let feedbackGradeList = [
  { label: '准确', value: 0 },
  { label: '轻微', value: 1 },
  { label: '略轻微', value: 2 },
  { label: '严重', value: 3 },
  { label: '略严重', value: 4 },
]
let loading: any = ref(false)
let columns = [
  {
    title: getLanguage('设备名称'),
    dataIndex: "machineName",
  },
  {
    title: getLanguage('所属工厂'),
    dataIndex: "factoryName",
  },
  {
    title: getLanguage('设备区域'),
    dataIndex: "groupName",
  },
  {
    title: getLanguage('反馈时间'),
    dataIndex: "date",
  },
  {
    title: getLanguage('报告严重程度'),
    dataIndex: "gradeName",
  },
  {
    title: getLanguage('故障模式'),
    dataIndex: "faultEvents",
  },
  {
    title: getLanguage('诊断描述'),
    dataIndex: "exportContent",
  },
  {
    title: getLanguage('反馈内容'),
    dataIndex: "degree",
  },
  {
    title: getLanguage('已调整内容'),
    dataIndex: "sonId",
  },
  {
    title: getLanguage('处理状态'),
    dataIndex: "status",
  },
  {
    title: getLanguage('操作'),
    dataIndex: "act",
  },
]
let dataList: any = ref([])

let visible: any = ref(false)
let visibleView: any = ref(false)
let columnsReport = [
  {
    title: '报告时间',
    dataIndex: "date",
  },
  {
    title: '严重程度',
    dataIndex: "gradeName",
  },
  {
    title: '故障模式',
    dataIndex: "faultEvents",
  },
  {
    title: '诊断描述',
    dataIndex: "exportContent",
  },
  {
    title: '客户反馈',
    dataIndex: "degree",
  },
  {
    title: '客户反馈原因',
    dataIndex: "reason",
  },
]
let feedbackForm: any = ref({
  sensorFaults: [{}]
})
let sensorFault: any = ref({})
let feedbackSearch: any = ref({
  type: 1
})

let paramList: any = ref([])

let chartDataList: any = ref({
  xData: [],
  yDatas: []
})
let myChart: any = null
let myChartView: any = null
let chartSelectlenged: any = {}

onMounted(() => {
  init()
})

const init = () => {
  getGroupList()
  getData()
}

// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
        (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

// 重置
const reset = () => {
  searchModel.value = {
    skip: 1,
    take: 20,
    groupId: null,
    machineId: null,
    date: null,
    status: null
  }
}

// 查询
const search = () => {
  searchModel.value.skip = 1
  getData()
}

const getData = async () => {
  dataList.value = []
  let config: any = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      machineId: searchModel.value.machineId,
      groupId: searchModel.value.groupId,
      status: searchModel.value.status,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  let result = await request.get('/api/report-feedbacks', config)
  let gradeList = [
    { value: -1, label: "健康", color: '#61c08f' },
    { value: 0, label: "可用", color: '#d2de49' },
    { value: 1, label: "警戒", color: '#cd5f3b' },
    { value: 2, label: "故障", color: '#921e37' },
  ];
  if (result?.data?.list?.length > 0) {
    result.data.list.map((d: any) => {
      d.machineName = d.machine?.machineName
      d.groupName = getGroupName(d.groupId)
      d.factoryName = getFactoryName(d.factoryId)
      d.gradeName = gradeList.find((p: any) => p.value === d?.report?.expertReport?.grade)?.label
      d.exportContent = d.report?.content
      let faults = []
      if (d?.report?.expertReport?.diagnosisDetails?.length > 0) {
        faults = d.report.expertReport.diagnosisDetails.map((d: any) => d.faultEvent)
      }
      faults = [...new Set(faults)]
      d.faultEvents = faults.toString()
    })

    dataList.value = result.data.list
  }

}

// 处理
const dealWith = (data: any) => {
  myChart && myChart.clear()
  let ev = JSON.parse(JSON.stringify(data))
  if (ev?.sensorFaults?.length > 0) {
    ev.sensorFaults.map((d: any) => {
      let labels: string[] = [];
      if (d.originThresholds) {
        labels = Object.keys(d.originThresholds)
      }
      let originThresholdList: any = []
      if (labels?.length) {
        originThresholdList = labels.map((p: any) => ({
          label: p,
          value: d.originThresholds[p]
        }))
      }
      d.originThresholdList = originThresholdList
    })


  }

  feedbackForm.value = {
    machineId: ev.machineId,
    sensorId: ev.sensorId,
    id: ev.id,
    title: `${ev.factoryName}/${ev.groupName}/${ev.machineName}`,
    reports: [
      {
        date: ev.date,
        gradeName: ev.gradeName,
        faultEvents: ev.faultEvents,
        exportContent: ev.exportContent,
        degree: ev.degree,
        reason: ev.reason,
      }
    ],
    sensorFaults: ev?.sensorFaults || [],
    degree: ev.degree
  }
  feedbackSearch.value.type = 1
  feedbackSearch.value.date = null
  visible.value = true
  if (ev?.sensorFaults?.length) {
    feedbackForm.value.index = 0
    feedbackSearch.value.type = 1
    selectSensorFault1({ target: { value: 0 } })
  }
}

const selectSensorFault1 = (ev: any) => {
  let index = ev.target.value
  paramList.value = []
  chartDataList.value = {
    xData: [],
    yDatas: []
  }
  selectSensorFault(index)
}

let searchSave: any = ref({})
const selectSensorFault = async (index: number, id: any = 'feedback1') => {
  if (index==null) return;
  let data = feedbackForm.value.sensorFaults[index]
  sensorFault.value = data

  let startDate = "";
  let endDate: any = "";
  if (feedbackSearch.value.type == 0) {
    if (
        feedbackSearch.value.date &&
        feedbackSearch.value.date.length === 2
    ) {
      startDate = transformDate(feedbackSearch.value.date[0]);
      endDate = transformDate(feedbackSearch.value.date[1]);
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = new Date();
    let num = 7;
    if (feedbackSearch.value.type == 2) {
      num = 30;
    }
    if (feedbackSearch.value.type == 3) {
      num = 60;
    }
    startDate = transformDate(new Date(new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num));
  }

  let singleExpertFaultNames = ["T8", "T14", "T15", "T16", "T17", "T19", "T20", "T21", "T22", "T23", "T30", "T35", "T39", "T40", "T48", "T49", "T51", "T54", "T62", "T63"];
  let isSingle = false
  if (data.scoreName.includes('S')) {
    isSingle = true
  } else {
    if (singleExpertFaultNames.find((p: any) => p === data.scoreName)) {
      isSingle = true
    } else {
      isSingle = false
    }
  }

  let varianceList = [
    { value: 0, variance: 0 },
    { value: 1, variance: -10 },
    { value: 2, variance: -5 },
    { value: 3, variance: 10 },
    { value: 4, variance: 5 },
  ]

  let degree = feedbackForm.value.degree || feedbackViewForm.value.degree

  let search = {
    machineId: feedbackForm.value.machineId || feedbackViewForm.value.machineId,
    // machineId: "649a40c3a12ce3eeaadc6a1e",
    sensorId: data.sensorId,
    tarFunc: data.faultFunction,
    funcTag: data.scoreName,
    paramArray: data.originThresholds,
    dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)],
    // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"],
    variance: varianceList.find((p: any) => p.value === degree)?.variance || 0,
    isSingle: isSingle
  }

  let result = await request.post('/scoreModel/score/multi-threshold', search)
  // params_rms 均方根  params_vari绝对值
  if (result?.data?.result?.length > 0) {
    let pData = result.data.result[0]
    for (let i in pData) {
      let d = pData[i]
      if (i === 'params_rms' || i === 'params_vari') {
        for (let i2 in d) {
          d[i2] = Number(d[i2].toFixed(2))
        }
      }
    }

    let objectToArray = (obj: any) => {
      return Object.keys(obj).map(function (key) {
        return {
          label: key,
          value: obj[key]
        };
      });
    }
    let afterParmar = null
    if (paramList.value?.length > 0) {
      afterParmar = paramList.value[3]
    }
    paramList.value = [
      {
        title: '原阈值',
        params: data.originThresholds,
        paramList: objectToArray(data.originThresholds)
      },
      {
        title: '基于平均绝对误差阈值',
        params: pData['params_vari'],
        paramList: objectToArray(pData['params_vari'])
      },
      {
        title: '基于均方根误差阈值',
        params: pData['params_rms'],
        paramList: objectToArray(pData['params_rms'])
      }
    ]
    if (afterParmar) {
      paramList.value.push(afterParmar)
    } else {
      paramList.value.push({
        title: '新阈值',
        params: data.originThresholds,
        paramList: objectToArray(data.originThresholds)
      })
      sensorFault.value.adjustThresholds = data.originThresholds
    }
  }

  // 原阈值数据
  let search1 = {
    machineId: feedbackForm.value.machineId || feedbackViewForm.value.machineId,
    // machineId: "649a40c3a12ce3eeaadc6a1e",
    sensorId: data.sensorId,
    tarFunc: data.faultFunction,
    funcTag: data.scoreName,
    paramArray: data.originThresholds,
    dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)]
    // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"]
  }
  searchSave.value = JSON.parse(JSON.stringify(search1))

  let result1 = await getThroldData(search1)

  if (!result1?.length) {
    return message.warning('该测点该时间段暂无分数曲线！')
  }
  result1 = result1.reverse()
  search1.paramArray = paramList.value[1]?.params
  let result2 = await getThroldData(search1)
  result2 = result2.reverse()
  search1.paramArray = paramList.value[2]?.params
  let result3 = await getThroldData(search1)
  result3 = result3.reverse()

  let xData = result1.map((p: any) => p.date)

  let parmarDataInfo = null
  if (chartDataList.value.yDatas?.length > 0) {
    parmarDataInfo = chartDataList.value.yDatas[3]
  }
  chartDataList.value = {
    xData,
    yDatas: [
      {
        title: '调整前趋势',
        yData: result1.map((p: any) => p[data.scoreName])
      },
      {
        title: '基于平均绝对误差阈值趋势',
        yData: result2.map((p: any) => p[data.scoreName])
      },
      {
        title: '基于均方根误差趋势',
        yData: result3.map((p: any) => p[data.scoreName])
      },
    ]
  }
  if (parmarDataInfo) {
    chartDataList.value.yDatas.push(parmarDataInfo)
  } else {
    chartDataList.value.yDatas.push({
      title: '调整后趋势',
      yData: []
    })
  }

  chartSelectlenged = {
    '调整前趋势': true,
    '基于平均绝对误差阈值趋势': true,
    '基于均方根误差趋势': true,
    '调整后趋势': true
  }
  initChart(id)


}

// 选中
const selectParmar = (ev: any) => {
  paramList.value[3].params = ev.params
  paramList.value[3].paramList = ev.paramList
  getAfterData(ev.params)
}

// 输入阈值查询
const inputParmar = async (ev: any) => {
  let paramArray: any = {}
  ev.paramList.map((d: any) => {
    paramArray[d.label] = d.value
  })
  getAfterData(paramArray)
}

// 获取调整后的趋势
const getAfterData = async (paramArray: any) => {
  let search = searchSave.value
  search.paramArray = paramArray
  sensorFault.value.adjustThresholds = paramArray
  let result = await getThroldData(search)
  chartDataList.value.yDatas[3].yData = result.map((p: any) => p[search.funcTag])
  initChart('feedback1')
}


// 绘制趋势图
const initChart = (id: any) => {
  let { xData, yDatas } = chartDataList.value
  const ec = echarts as any;
  if (!document.getElementById(id)) return;
  myChart = ec.init(document.getElementById(id));

  let min = 0,
      max = 100,
      colors: any[] = [];

  let option: any = null;
  let LengData: any[] = [],
      SelectedData = {},
      YaData,
      SeriesData,
      DataInfo: any[] = [];
  colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];

  yDatas.map((item: any, index: any) => {
    LengData.push(item.title);
    DataInfo.push({
      name: item.title,
      type: "line",
      data: item.yData,
      smooth: false,
      symbolSize: 3,
      connectNulls: true,
    });
  });

  function Init(sel: { [x: string]: any }, dataInfo: any[]) {
    SelectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = 0;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }
    option = {
      color: colors,
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0,
        animation: false,
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        right: "5%",
        left: "7%",
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        data: LengData,
        selected: SelectedData,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: "#fff",
            fontSize: 16,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            color: "#fff",
          },
        },
      ],
      series: SeriesData,
    };
  }

  Init(chartSelectlenged, DataInfo);

  if (option && typeof option === "object") {
    myChart && myChart.clear();
    myChart && myChart.setOption(option, true);
  }

  myChart
      .off("legendselectchanged")
      .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        chartSelectlenged = params.selected;
        Init(chartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
          myChart && myChart?.clear();
          myChart && myChart?.setOption(option, true);
        }
      });


}


// 获取阈值分数数据
const getThroldData = async (search: any) => {
  let result = await request.post('/scoreModel/score/trim-threshold', search)
  return result?.data?.result || []
}

// 提交阈值
const submitParmar = async () => {
  let feedbackFormInfo = JSON.parse(JSON.stringify(feedbackForm.value))
  let sensorFaults = feedbackFormInfo.sensorFaults
  sensorFaults.map((d: any) => {
    delete d.originThresholdList
  })

  let submit = {
    id: feedbackForm.value.id,
    sensorFaults,
  }

  let result = await request.post('/api/report-feedbacks/adjust', submit)
  if (result?.status === 200) {
    message.success('操作成功!')
    visible.value = false
    getData()
  } else {
    message.warning('操作失败!')
  }
}


// 查看详情
let chartViewList: any = ref([])
let feedbackViewForm: any = ref({})

const view = (data: any) => {
  console.log('data', data)
  myChartView && myChartView.clear()
  let ev = JSON.parse(JSON.stringify(data))
  if (ev?.sensorFaults?.length > 0) {
    ev.sensorFaults.map((d: any) => {
      let labels = Object.keys(d.originThresholds)
      let originThresholdList: any = []
      if (labels?.length) {
        originThresholdList = labels.map((p: any) => ({
          label: p,
          value: d.originThresholds[p]
        }))
      }
      d.originThresholdList = originThresholdList
    })
  }
  feedbackForm.value = {}
  feedbackViewForm.value = {
    machineId: ev.machineId,
    sensorId: ev.sensorId,
    id: ev.id,
    title: `${ev.factoryName}/${ev.groupName}/${ev.machineName}`,
    reports: [
      {
        date: ev.date,
        gradeName: ev.gradeName,
        faultEvents: ev.faultEvents,
        exportContent: ev.exportContent,
        degree: ev.degree,
        reason: ev.reason,
      }
    ],
    sensorFaults: ev?.sensorFaults || [],
    degree: ev.degree
  }
  feedbackSearch.value.type = 1
  feedbackSearch.value.date = null
  visibleView.value = true
  if (ev?.sensorFaults?.length) {
    feedbackViewForm.value.index = 0
    feedbackSearch.value.type = 1
    selectSensorFault2({ target: { value: 0 } })
  }
}

const selectSensorFault2 = (ev: any) => {
  let index = ev.target.value
  paramList.value = []
  chartDataList.value = {
    xData: [],
    yDatas: []
  }
  selectSensorFaultView(index)
}

const selectSensorFaultView = async (index: any) => {
  let data = feedbackViewForm.value.sensorFaults[index]
  let startDate = "";
  let endDate: any = "";
  if (feedbackSearch.value.type == 0) {
    if (
        feedbackSearch.value.date &&
        feedbackSearch.value.date.length === 2
    ) {
      startDate = transformDate(feedbackSearch.value.date[0]);
      endDate = transformDate(feedbackSearch.value.date[1]);
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = new Date();
    let num = 7;
    if (feedbackSearch.value.type == 2) {
      num = 30;
    }
    if (feedbackSearch.value.type == 3) {
      num = 60;
    }
    startDate = transformDate(new Date(new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num));
  }


  let search = {
    machineId: feedbackForm.value.machineId || feedbackViewForm.value.machineId,
    // machineId: "649a40c3a12ce3eeaadc6a1e",
    sensorId: data.sensorId,
    tarFunc: data.faultFunction,
    funcTag: data.scoreName,
    paramArray: data.originThresholds,
    dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)]
    // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"]
  }
  let result1 = await getThroldData(search)
  search.paramArray = data.adjustThresholds
  let result2 = await getThroldData(search)


  chartSelectlenged = {
    '调整前趋势': true,
    '调整后趋势': true
  }

  let xData = result1.map((p: any) => p.date)
  chartDataList.value = {
    xData,
    yDatas: [
      {
        title: '调整前趋势',
        yData: result1.map((p: any) => p[data.scoreName])
      },
      {
        title: '调整后趋势',
        yData: result2.map((p: any) => p[data.scoreName])
      },
    ]
  }

  initChart('feedback2')
}


const disabledDate2 = (current: Dayjs) => {
  if (!feedbackSearch.value.date || (feedbackSearch.value.date.value as any).length === 0) {
    return false;
  }
  const tooLate = feedbackSearch.value.date[0] && current.diff(feedbackSearch.value.date[0], "days") > 180;
  const tooEarly = feedbackSearch.value.date[1] && feedbackSearch.value.date[1].diff(current, "days") > 180;
  return tooEarly || tooLate;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "inline" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('设备区域')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).groupId,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                      style: {"width":"220px"},
                      onChange: selectGroup,
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('设备')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).machineId,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                      style: {"width":"220px"},
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('反馈时间')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_range_picker, {
                      allowClear: "",
                      value: _unref(searchModel).date,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).date) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('处理状态')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).status,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).status) = $event)),
                      style: {"width":"220px"},
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", {
                        onClick: reset,
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                      }, _toDisplayString(_unref(getLanguage)('重置')), 1),
                      _createElementVNode("div", {
                        onClick: search,
                        class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(getLanguage)('查询')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_table, {
                bordered: "",
                columns: _unref(columns),
                dataSource: _unref(dataList),
                pagination: false,
                loading: _unref(loading),
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'degree')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_unref(feedbackGradeList).find((p) => p.value === text)?.label), 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'status')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_unref(statusList).find((p) => p.value === text)?.label), 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (record.status === 1)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              onClick: ($event: any) => (dealWith(record)),
                              class: "theme-table-link"
                            }, "处理", 8, _hoisted_4))
                          : _createCommentVNode("", true),
                        (record.status !== 1)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              onClick: ($event: any) => (view(record)),
                              class: "theme-table-link"
                            }, "查看详情", 8, _hoisted_5))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "dataSource", "loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          visible: _unref(visible),
          "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_isRef(visible) //@ts-ignore
 ? visible.value = false : visible = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(feedbackForm).title), 1),
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "my-2" }, "诊断报告", -1)),
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: _unref(columnsReport),
                "data-source": _unref(feedbackForm).reports,
                pagination: false
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'degree')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_unref(feedbackGradeList).find((p) => p.value === text)?.label), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source"]),
              _cache[28] || (_cache[28] = _createElementVNode("div", { class: "my-2" }, "诊断调整", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_a_radio_group, {
                  value: _unref(feedbackForm).index,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(feedbackForm).index) = $event)),
                  onChange: selectSensorFault1
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedbackForm).sensorFaults, (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        style: {"margin-top":"10px"},
                        key: index,
                        value: index
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _cache[24] || (_cache[24] = _createElementVNode("div", null, "测点：", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.sensorPlace) + ",", 1),
                            _cache[25] || (_cache[25] = _createElementVNode("div", null, "故障指标：", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.faultName) + ",", 1),
                            _createElementVNode("div", _hoisted_10, _toDisplayString(item.faultFunction), 1),
                            _createElementVNode("div", _hoisted_11, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.originThresholdList, (f, index2) => {
                                return (_openBlock(), _createBlock(_component_a_tag, {
                                  key: index2,
                                  color: "green"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(f.label) + ":", 1),
                                    _createElementVNode("span", null, _toDisplayString(f.value), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '1' }]),
                    style: _normalizeStyle([{
                        background:
                        _unref(feedbackSearch).type == '1' ? '#072498' : '',
                        }, {"border-radius":"5px 0 0 5px"}]),
                    onClick: _cache[6] || (_cache[6] = ($event: any) => ((_unref(feedbackSearch).type = '1'), selectSensorFault(_unref(feedbackForm).index)))
                  }, " 近一周 ", 6),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '2' }]),
                    style: _normalizeStyle({
                        background:
                        _unref(feedbackSearch).type == '2' ? '#072498' : '',
                        }),
                    onClick: _cache[7] || (_cache[7] = ($event: any) => ((_unref(feedbackSearch).type = '2'), selectSensorFault(_unref(feedbackForm).index)))
                  }, " 近一月 ", 6),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '3' }]),
                    style: _normalizeStyle({
                        background:
                        _unref(feedbackSearch).type == '3' ? '#072498' : '',
                        }),
                    onClick: _cache[8] || (_cache[8] = ($event: any) => ((_unref(feedbackSearch).type = '3'), selectSensorFault(_unref(feedbackForm).index)))
                  }, " 近两月 ", 6),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '0' }]),
                    style: _normalizeStyle([{
                        background:
                        _unref(feedbackSearch).type == '0' ? '#072498' : '',
                        }, {"border-radius":"0 5px 5px 0"}]),
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(feedbackSearch).type = '0'))
                  }, " 自定义 ", 6),
                  (_unref(feedbackSearch).type == '0')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_a_range_picker, {
                          value: _unref(feedbackSearch).date,
                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(feedbackSearch).date) = $event)),
                          "disabled-date": disabledDate2,
                          onCalendarChange: _unref(feedbackSearch)
                        }, null, 8, ["value", "onCalendarChange"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (selectSensorFault(_unref(feedbackForm).index))),
                    class: "btn3 ml-2 theme-datesetected"
                  }, " 查询 ")
                ])
              ]),
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "mt-4" }, [
                _createElementVNode("div", {
                  id: "feedback1",
                  style: {"height":"340px"},
                  class: "w-full"
                })
              ], -1)),
              _createElementVNode("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paramList), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "w-full flex items-center px-12 py-2",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(item.title), 1),
                    (index !== 3)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.paramList, (f, index2) => {
                            return (_openBlock(), _createBlock(_component_a_tag, {
                              key: index2,
                              color: "green"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(f.label) + ":", 1),
                                _createElementVNode("span", null, _toDisplayString(f.value), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (index === 3)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.paramList, (f, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "ml-4",
                              key: index2
                            }, [
                              _createElementVNode("span", null, _toDisplayString(f.label) + ":", 1),
                              _createVNode(_component_a_input_number, {
                                style: {"margin-left":"15px"},
                                value: f.value,
                                "onUpdate:value": ($event: any) => ((f.value) = $event),
                                controls: false
                              }, null, 8, ["value", "onUpdate:value"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    (index !== 3)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          onClick: ($event: any) => (selectParmar(item)),
                          class: "btn3 ml-2 px-4"
                        }, " 选中 ", 8, _hoisted_19))
                      : _createCommentVNode("", true),
                    (index === 3)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 3,
                          onClick: ($event: any) => (inputParmar(item)),
                          class: "btn3 ml-2 px-4"
                        }, " 查询 ", 8, _hoisted_20))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", {
                  class: "btn-submit-noradius",
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"18px","text-align":"center","cursor":"pointer"},
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (submitParmar()))
                }, " 保存阈值 ")
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibleView),
          "onUpdate:visible": _cache[22] || (_cache[22] = ($event: any) => (_isRef(visibleView) ? (visibleView).value = $event : visibleView = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(visibleView) //@ts-ignore
 ? visibleView.value = false : visibleView = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(feedbackViewForm).title), 1),
              _cache[33] || (_cache[33] = _createElementVNode("div", { class: "my-2" }, "诊断报告", -1)),
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: _unref(columnsReport),
                "data-source": _unref(feedbackViewForm).reports,
                pagination: false
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'degree')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_unref(feedbackGradeList).find((p) => p.value === text)?.label), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source"]),
              _cache[34] || (_cache[34] = _createElementVNode("div", { class: "my-2" }, "诊断调整", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_a_radio_group, {
                  value: _unref(feedbackViewForm).index,
                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(feedbackViewForm).index) = $event)),
                  onChange: selectSensorFault1
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedbackViewForm).sensorFaults, (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        style: {"margin-top":"10px"},
                        key: index,
                        value: index
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_25, [
                            _cache[31] || (_cache[31] = _createElementVNode("div", null, "测点：", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.sensorPlace) + ",", 1),
                            _cache[32] || (_cache[32] = _createElementVNode("div", null, "故障指标：", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.faultName) + ",", 1),
                            _createElementVNode("div", _hoisted_26, _toDisplayString(item.faultFunction), 1),
                            _createElementVNode("div", _hoisted_27, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.originThresholdList, (f, index2) => {
                                return (_openBlock(), _createBlock(_component_a_tag, {
                                  key: index2,
                                  color: "green"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(f.label) + ":", 1),
                                    _createElementVNode("span", null, _toDisplayString(f.value), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '1' }]),
                    style: _normalizeStyle([{
                        background:
                        _unref(feedbackSearch).type == '1' ? '#072498' : '',
                        }, {"border-radius":"5px 0 0 5px"}]),
                    onClick: _cache[16] || (_cache[16] = ($event: any) => ((_unref(feedbackSearch).type = '1'), selectSensorFaultView(_unref(feedbackViewForm).index)))
                  }, " 近一周 ", 6),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '2' }]),
                    style: _normalizeStyle({
                        background:
                        _unref(feedbackSearch).type == '2' ? '#072498' : '',
                        }),
                    onClick: _cache[17] || (_cache[17] = ($event: any) => ((_unref(feedbackSearch).type = '2'), selectSensorFaultView(_unref(feedbackViewForm).index)))
                  }, " 近一月 ", 6),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '3' }]),
                    style: _normalizeStyle({
                        background:
                        _unref(feedbackSearch).type == '3' ? '#072498' : '',
                        }),
                    onClick: _cache[18] || (_cache[18] = ($event: any) => ((_unref(feedbackSearch).type = '3'), selectSensorFaultView(_unref(feedbackViewForm).index)))
                  }, " 近两月 ", 6),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn2 px-3 py-1 theme-datesetect", { 'text-white theme-datesetected': _unref(feedbackSearch).type == '0' }]),
                    style: _normalizeStyle([{
                        background:
                        _unref(feedbackSearch).type == '0' ? '#072498' : '',
                        }, {"border-radius":"0 5px 5px 0"}]),
                    onClick: _cache[19] || (_cache[19] = ($event: any) => (_unref(feedbackSearch).type = '0'))
                  }, " 自定义 ", 6),
                  (_unref(feedbackSearch).type == '0')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createVNode(_component_a_range_picker, {
                          value: _unref(feedbackSearch).date,
                          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_unref(feedbackSearch).date) = $event)),
                          "disabled-date": disabledDate2,
                          onCalendarChange: _unref(feedbackSearch)
                        }, null, 8, ["value", "onCalendarChange"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (selectSensorFaultView(_unref(feedbackViewForm).index))),
                    class: "btn3 ml-2 theme-datesetected"
                  }, " 查询 ")
                ])
              ]),
              _cache[35] || (_cache[35] = _createElementVNode("div", { class: "mt-4" }, [
                _createElementVNode("div", {
                  id: "feedback2",
                  style: {"height":"340px"},
                  class: "w-full"
                })
              ], -1))
            ])
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})